import React from 'react'
import { PageTitle } from '@app/components/general/page-title'
import { MarketingDrawer } from '@app/components/general/drawers/marketing-drawer'
import { GrHelp } from 'react-icons/gr'
import { useRouter } from 'next/router'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import Trans from 'next-translate/Trans'
import { Paragraph } from '@app/components/stateless/typo/paragraph'
import { SectionContainer } from '@app/components/stateless/containers/section-container'
import { Address } from '@app/components/general/mailto'
import { Link } from '@app/components/stateless/typo/link'
import loadNamespaces from 'next-translate/loadNamespaces'
import type { GetStaticProps } from 'next'
import { I18nDictionary } from 'next-translate'
import { Header2 } from '@app/components/general/header'
import { SUPPORT_EMAIL } from '@app/configs/app-config'

export const getStaticProps: GetStaticProps<{
  __lang: string
  __namespaces?: Record<string, I18nDictionary>
}> = async (ctx) => {
  return {
    props: await loadNamespaces({
      ...ctx,
      pathname: '/404',
    }),
  }
}

// TODO: translations for page
const PageNotFound = () => {
  const router = useRouter()
  const { t } = useTranslation('404')

  const similiarMatch = {
    name: '',
    href: '',
  }
  const pathName = (router && router.asPath) || ''

  // reccomend api route
  if (pathName.includes('api')) {
    similiarMatch.name = t('common:api-info')
    similiarMatch.href = '/api-info'
  }
  // reccomend api route
  if (pathName.includes('dashboard')) {
    similiarMatch.name = t('common:dashboard')
    similiarMatch.href = '/dashboard'
  }
  // rec api route
  if (pathName.includes('settings')) {
    similiarMatch.name = t('common:settings')
    similiarMatch.href = '/settings'
  }

  return (
    <MarketingDrawer>
      <SectionContainer container block>
        <GrHelp className='grIcon text-2xl' />
        <PageTitle>{t('header')}</PageTitle>
        <div className='space-y-4 pb-6'>
          <Trans
            i18nKey='404:header-d'
            components={{
              component: <Paragraph key={'404-h1'} />,
              first: (
                <Address
                  className='underline text-blue-600 hover:text-blue-400 capitalize'
                  email={SUPPORT_EMAIL}
                  key={'404-h2'}
                />
              ),
            }}
          />

          <div>
            <Header2>{t('subtitle')}</Header2>
            <Paragraph>{t('subtitle-d')}</Paragraph>
          </div>

          {similiarMatch.href ? (
            <Trans
              i18nKey='404:similar'
              values={{ route: similiarMatch.name }}
              components={[
                <div className='p-4 border' key={'404-1'} />,
                <Link
                  href={similiarMatch.href}
                  className={'text-blue-800 font-bold hover:text-blue-600'}
                  key={'404-2'}
                />,
              ]}
            />
          ) : null}
        </div>
      </SectionContainer>
    </MarketingDrawer>
  )
}

PageNotFound.meta = {
  translation: '404',
}

export default PageNotFound

// export default metaSetter(
//   { PageNotFound },
//   {
//     translation: '404',
//   }
// )
